import React from 'react';
import '../style.scss';

const Instagram = (props) => {

    return (

        <svg id="Layer_1" data-name="Layer 1" className="btn-social" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.82 74.82">
            <defs></defs>
            <path  d="M509.73,58.44a21.85,21.85,0,0,0-21.82,21.83v31.17a21.85,21.85,0,0,0,21.83,21.82h31.17a21.85,21.85,0,0,0,21.82-21.83V80.26A21.85,21.85,0,0,0,540.9,58.44Zm38,12a3,3,0,1,1-3,3A3,3,0,0,1,547.77,70.41Zm-22.44,6a19.45,19.45,0,1,1-19.45,19.45A19.47,19.47,0,0,1,525.33,76.4Zm0,6A13.47,13.47,0,1,0,538.8,95.85,13.47,13.47,0,0,0,525.33,82.38Z" transform="translate(-487.91 -58.44)" />
        </svg>

    )
}
export default Instagram;