import React from 'react';
import '../style.scss';

const Facebook = (props) => {

    return (
        <svg id="Layer_1" className="btn-social" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.37 75.38">
            <defs></defs>
            <path  d="M404.47,58.16H342.79A6.85,6.85,0,0,0,335.94,65v61.68a6.85,6.85,0,0,0,6.85,6.85h34.27V102.7H366.78V92.42h10.28V86.9c0-10.45,5.09-15,13.78-15a50.25,50.25,0,0,1,7.4.45v9.83h-5.93c-3.69,0-5,2-5,5.89v4.39h10.81l-1.47,10.28h-9.34v30.84h17.13a6.85,6.85,0,0,0,6.85-6.85V65A6.82,6.82,0,0,0,404.47,58.16Z" transform="translate(-335.94 -58.16)" />
        </svg>

    )
}
export default Facebook;