import React, {useState} from 'react';
//import Modal from 'components/UI/Modal/Modal';
import './style.scss';
import useToggle from 'hooks/useToggle';
import Wrapper from '../../templates/Wrapper/Wrapper';
import upsideFoodsLogo from 'assets/images/upside-foods-logo.png';
import CeoSignature from 'components/CeoSignature/CeoSignature';



const Home = (props) => {
    const {lang, isMobile} = props;
    //const [showModal, setShowModal] = useState(false);
    const [isToggled, toggle] = useToggle(false);

    //console.log('home props ', props);

    return (
        <>
            <Wrapper addClass="home" {...props}>
                <div className="blockwrap bg-wrap">
                    <div className="container">
                        <div className="content">
                            <a href="https://www.upsidefoods.com" className="logo" title="upside foods website" target="_blank" rel="noreferrer"><img src={upsideFoodsLogo} alt='upside foods' /></a>

                            <h1>Dear chickens <br /> of the world,</h1>
                            <p>You may be dismissed from the dinner table.</p>
                            <p>Why? Well, we have some juicy news for you. UPSIDE Foods has received a &lsquo;No Questions Letter&rsquo; from the FDA. That’s good news for everybody—for the entire world, really. But for you? For chickens? It might be the best news ever. Let me explain.</p>
                            <p>UPSIDE Foods makes cultivated meat. It's not vegan or vegetarian, it’s delicious meat grown directly from animal cells. That’s right—animal <em>cells</em>. It might sound crazy, but it’s based on a simple principle: animal cells are the building blocks of the meat that we humans love so much.</p>
                            <p>So how does it work? In a nutshell, we take a sample of animal cells, place them in a vessel we call a cultivator (you can see why we call it “cultivated meat”), and feed them the right blend of nutrients for them to multiply and grow. After about three weeks, the meat is harvested and ready to enjoy.</p>
                            <p>In short, we’re making meat with a big UPSIDE. Especially for you. Because the first cultivated meat we’ll be bringing to the world is—you guessed it—chicken.</p>
                            <p>So what does getting a ‘No Questions Letter’ mean, exactly? It means that the FDA accepts our safety conclusion and UPSIDE’s cultivated chicken will be available following USDA inspection and label approval. It’s an important step on the road to bringing cultivated chicken to the market in the U.S., and gets UPSIDE closer to being on tables everywhere.</p>
                            <p>And what does <em>that</em> mean? It means a whole new future is around the corner. And in that future, we might be eating just as much meat as we always have. But a lot fewer animals are going to have to suffer for it.</p>
                            <p>We wanted you, the chickens of the world, to be the first to know. And we hope it brings a smile to your beaks.</p>
                            <p className="txt-center">With love,</p>

                            <p className="txt-center"><CeoSignature />{/*<img className="ceo-signature" src={ceoSignature} alt="ceo signature" />*/}</p> 

                            <p className="txt-center">Uma Valeti, M.D.
                                <br />
                                CEO/Founder
                                
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div className="blockwrap logo">
                    <a href="https://www.upsidefoods.com" className="logo" title="upside foods website" target="_blank" rel="noreferrer"><img src={upsideFoodsLogo} alt='upside foods' /></a>
                </div>
                
            </Wrapper>
        </>
    )
}
export default Home;