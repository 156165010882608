import React from 'react';
import '../style.scss';

const Youtube = (props) => {

    return (

        <svg id="Layer_1" className="btn-social" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.29 76.68">
            <defs></defs>
            <path  d="M725.78,70.69c-.95-5.27-5.5-9.11-10.78-10.31-7.9-1.68-22.52-2.87-38.34-2.87s-30.67,1.2-38.58,2.87c-5.27,1.2-9.83,4.79-10.78,10.31a157.7,157.7,0,0,0-1.92,25.16A130.32,130.32,0,0,0,627.53,121c1,5.27,5.51,9.11,10.78,10.31,8.39,1.68,22.77,2.87,38.59,2.87s30.2-1.2,38.59-2.87c5.27-1.2,9.82-4.79,10.78-10.31a185.53,185.53,0,0,0,2.4-25.16A167.73,167.73,0,0,0,725.78,70.69Zm-62.06,41.94V79.08L693,95.85Z" transform="translate(-625.38 -57.51)" />
        </svg>

    )
}
export default Youtube;