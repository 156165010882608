import React from 'react';
import './style.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Wrapper = (props) => {
    
    let { addClass, children, isMobile } = props;

    return (
        <>
            {!isMobile && <Header {...props}/>}
            <div className={`wrapper ${addClass}`}>
                {children}
            </div>
           <Footer {...props}/>
        </>

    )
}
export default Wrapper;
