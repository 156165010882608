import React from 'react';
import './style.scss';
import upsideFoodsLogo from '../../assets/images/upside-foods-logo.png';
import SocialButtons from 'components/SocialButtons/SocialButtons';

const Header = (props) => {
    const { lang } = props;
    
    return (
        <div className="blockwrap header">
            
           
            <div className="container">
                <a href="https://www.upsidefoods.com" className="logo" title="upside foods website" target="_blank" rel="noreferrer"><img src={upsideFoodsLogo} alt='upside foods' /></a>
                <SocialButtons />
            </div>
            
           
        </div>

    )
}
export default Header;