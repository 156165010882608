import React from 'react';
import './style.scss';
import TwitterIcon from './icons/Twitter/Twitter';
import FacebookIcon from './icons/Facebook/Facebook';
import InstagramIcon from './icons/Instagram/Instagram';
import YoutubeIcon from './icons/Youtube/Youtube';
import LinkedinIcon from './icons/Linkedin/Linkedin';

const SocialButtons = (props) => {

    return (
        
        <div className="social-buttons">

            <a href="https://twitter.com/upsidefoods" title="share on twitter" target="_blank" rel="noreferrer"><TwitterIcon /></a>
            <a href="https://www.facebook.com/upsidefoods" title="share on facebook" target="_blank" rel="noreferrer"><FacebookIcon /></a>
            <a href="https://www.instagram.com/upsidefoods/?hl=en" title="check out instagram" target="_blank" rel="noreferrer"><InstagramIcon /></a>
            <a href="https://www.linkedin.com/company/upsidefoods" title="linkedin"target="_blank" rel="noreferrer"><LinkedinIcon /></a>
            <a href="https://www.youtube.com/channel/UCRwJNgLgr_Sj0jiUCk--9vg" title="youtube" target="_blank" rel="noreferrer"><YoutubeIcon /></a>
          
        </div>

    )
}
export default SocialButtons;