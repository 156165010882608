import React from 'react';
import SocialButtons from '../SocialButtons/SocialButtons';
import './style.scss';
//import MailChimpForm from 'components/MailChimpForm/MailChimpForm';
import MailchimpFormContainer from 'components/MailChimpFormContainer/MailChimpFormContainer';

const Footer = (props) => {
    const { isMobile } = props;
    return (
        // <div className="blockwrap footer">
        //       <SocialButtons {...props} />
        // </div>
        <div className="blockwrap footer">
            {/* <div className="container">
                <p>Stay looped in with what's cookin' at UPSIDE.<br />Sign up for our newsletter!</p>
                <MailChimpForm {...props} />
            </div> */}
            <div className="container">
               
                <MailchimpFormContainer {...props} />
            </div>
            { isMobile &&<SocialButtons {...props} />}
        </div>
    )
}
export default Footer;