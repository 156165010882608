import React from 'react';
import { useState } from 'react';
//import './mcFormStyles.scss';
import './style.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
   
    const handleSubmit = (e) => {
        e.preventDefault();
        // email &&
        // email.indexOf("@") > -1 &&
        // onValidated({
        //     EMAIL: email,
        //     MMERGE5: consent
        // });

        if ( email &&
            email.indexOf("@") > -1 &&
            consent !== '') {
                setErrorMsg(false);
                onValidated({
                    EMAIL: email,
                    MMERGE5: consent
                });
        } else {
            console.log('Please enter a valid email and click the consent button.');
            setErrorMsg('Please enter a valid email and click the consent button.');
        }
        console.log('email ', email);
    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)} className="subscribe-mailchimp"> 
                <p>Stay looped in with what's cookin' at UPSIDE.<br />Sign up for our newsletter!</p>
                <div className="radio-wrap"><input type="radio" value="I confirm that I am 13 years old (18 in CA) or older" name="MMERGE5" id="mce-MMERGE5-0" onChange={(e) => setConsent(e.target.value)} /> <label className="radio-label" htmlFor="mce-MMERGE5-0">I confirm that I am 13 years old<br /> (18 in CA) or older.</label></div>
                <div className="email-wrap"><input name="EMAIL" type="email" placeholder="Email" className="email" onChange={(e) => setEmail(e.target.value)}  /> <button type="submit" className="submit">Subscribe</button> </div><br />
                <br />
            
            
            </form>
        
             {status === "sending" && (
                <div className="msg-alert alert--sending">
                    sending...
                </div>
            )}
            {status === "error" && (
                <div 
                    className="msg-alert alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="msg-alert alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {errorMsg !== false && (
                <div
                    className="msg-alert alert--success"
                    dangerouslySetInnerHTML={{ __html: errorMsg }}
                />
            )}
        </>
    );
}

const MailchimpFormContainer = props => {

    const postUrl = `https://upsidefoods.us12.list-manage.com/subscribe/post?u=2a48f9bbe826a0f176e35be51&id=ba2a716fec&f_id=00adcbe1f0`;

    return (
        <div className="form-container">
            
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => {
                            subscribe(formData)
                            console.log('formdata ',formData)
                        }}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormContainer;